import React, { useContext, useEffect } from 'react';
import { Text, Button, PopoverTrigger, Avatar, PopoverBody, Layout } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { FeatureAppLoader } from '@feature-hub/react';
import { TrackingDataV2, TrackingEventV2 } from '@oneaudi/audi-tracking-service';
import RichText from '../RichText';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiUserMenuProps } from '../../interfaces/header-components.interfaces';
import { coreRenderer } from '../RichText/core-renderer';
import { userMenuContent } from '../../i18n/usermenuContent';
import { useProfilePictureLamda } from '../../hooks/use-profile-picture';
import { useAuth } from '../../hooks/use-auth';
import { useUserInfo } from '../../hooks/use-user-info';
import { useEnvConfig } from '../../hooks/use-env-config';
import { HeaderStyledLogin, StyledPopoverContainer } from '../AudiHeader/AudiHeaderStyles';
import { EventAction } from '../../utils/tracking';
import { TrackingServiceContext } from '../../context';

const StyledAvatar = styled(Avatar)`
  & img {
    position: inherit;
  }
`;

const StyledLayout = styled(Layout)`
  overflow: hidden;
`;

const AudiUserMenu: React.FC<AudiUserMenuProps> = ({
  authService,
  envConfigService,
  headerNavigationItemsAmount,
  isOpen,
  onClickUserMenu,
  marketBaseUrl,
  redirectUrl,
  userAccountUrl,
  loginFeatureAppUrl,
  useUserMenu,
  eventService,
  supplier,
  totalActionItems,
}) => {
  const benefitHeadlineLabel = useI18n(userMenuContent.benefitHeadline);
  const benefitSubHeadlineLabel = useI18n(userMenuContent.benefitSubHeadline);
  const benefitContent = useI18n(userMenuContent.benefitContent);
  const benefitLoginLabel = useI18n(userMenuContent.benefitLogin);
  const benefitMoreLabel = useI18n(userMenuContent.benefitMore);
  const benefitMarketUrl = useI18n(userMenuContent.benefitMarketUrl);
  const benefitMyAudi = useI18n(userMenuContent.benefitMyAudi);
  const benefitLogout = useI18n(userMenuContent.benefitLogout);
  const flyoutLabel = useI18n(userMenuContent.flyoutLabel);
  const { isAuthenticated, accessToken } = useAuth(authService);
  const { idk } = useEnvConfig(envConfigService);

  const userInfo = useUserInfo(idk, accessToken);
  const { personalData } = userInfo;

  const profilePicture = useProfilePictureLamda(accessToken, idk, eventService);

  // Extract the first name and last name from personalData
  const firstName = personalData?.firstName;
  const lastName = personalData?.lastName;

  const initials = `${firstName ? firstName.normalize().charAt(0).toUpperCase() : ''}${
    lastName ? lastName.normalize().charAt(0).toUpperCase() : ''
  }`;

  const { trackingService } = useContext(TrackingServiceContext);

  const trackUserAuth = () => {
    const additionalObjects = {
      user: [
        {
          segment: {
            isLoggedIn: isAuthenticated,
          },
        },
      ],
    };
    const userAuthenticationStatusEvent: TrackingEventV2 = {
      eventInfo: {
        eventAction: EventAction.NONE_INTERACTION,
        eventName: isAuthenticated ? 'user logged in' : 'user logged out',
      },
      attributes: {
        componentName: EventAction.COMPONENT_NAME,
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        value: '',
        pos: '',
      },
    };
    trackingService?.track({
      event: userAuthenticationStatusEvent,
      componentUpdate: additionalObjects,
    });
  };

  useEffect(() => {
    // this useEffect Hook is going to execute trackAuth() every 30 Minutes whenever the user remains inactive
    // https://collaboration.msi.audi.com/confluence/pages/viewpage.action?spaceKey=EXCHANGE&title=20-08+-+User+Menu+-+Update+Login+Status
    const storageKey = 'auth-tracking-timestamp';
    const checkTimePassed = () => {
      const timeout = 30 * 60 * 1000; // thirty minutes in milliseconds
      const storedTimestamp = localStorage.getItem(storageKey);
      const currentTimestamp = new Date().getTime();
      if (!storedTimestamp || currentTimestamp - parseInt(storedTimestamp, 10) >= timeout) {
        trackUserAuth();
        localStorage.setItem(storageKey, currentTimestamp.toString());
      }
    };
    checkTimePassed();
    const interval = setInterval(checkTimePassed, 60 * 1000); // one minutes in milliseconds (60 * 1000)
    return () => clearInterval(interval);
  }, []);

  const renderLoggedOutState = () => {
    const handleLoginClick = (event: React.MouseEvent) => {
      event.preventDefault();
      const loginClickEvent: TrackingDataV2 = {
        event: {
          eventInfo: {
            eventAction: EventAction.EXIT,
            eventName: 'one header - myaudi - click on login',
          },
          attributes: {
            componentName: EventAction.COMPONENT_NAME,
            label: benefitLoginLabel,
            targetURL: '',
            clickID: '',
            elementName: 'button',
            value: '',
            pos: '',
          },
        },
      };
      trackingService?.track(loginClickEvent);
      authService.login(undefined, typeof window !== 'undefined' ? userAccountUrl : undefined);
    };
    const handleRegistrationClick = (event: React.MouseEvent) => {
      event.preventDefault();
      const registrationClickEvent: TrackingDataV2 = {
        event: {
          eventInfo: {
            eventAction: EventAction.EXIT,
            eventName: 'one header - myaudi - click on register',
          },
          attributes: {
            componentName: EventAction.COMPONENT_NAME,
            label: benefitMoreLabel,
            targetURL: '',
            clickID: '',
            elementName: 'button',
            value: '',
            pos: '',
          },
        },
      };
      trackingService?.track(registrationClickEvent);
      authService.login(undefined, typeof window !== 'undefined' ? userAccountUrl : undefined);
    };

    const handleDetailsClick = (event: React.MouseEvent) => {
      event.preventDefault();
      const detailsClickEvent: TrackingDataV2 = {
        event: {
          eventInfo: {
            eventAction: EventAction.EXIT,
            eventName: 'one header - myaudi - click on more information',
          },
          attributes: {
            componentName: EventAction.COMPONENT_NAME,
            label: benefitMarketUrl,
            targetURL: '',
            clickID: '',
            elementName: 'text link',
            value: '',
            pos: '',
          },
        },
      };
      trackingService?.track(detailsClickEvent);
      if (typeof marketBaseUrl === 'string') {
        window.location.href = marketBaseUrl;
      }
    };

    return (
      <PopoverTrigger placement="bottom right" tip>
        {(triggerProps) => (
          <>
            <Button
              aria-label={flyoutLabel}
              variant="icon-tertiary"
              icon="user"
              size="small"
              type="button"
              onClick={onClickUserMenu}
              data-testid="FAUSERMENU_USERICON"
              {...triggerProps}
            />
            <StyledPopoverContainer aria-label="myAudi Customer Area">
              <PopoverBody>
                <Text
                  data-testid="FAUSERMENU_BENEFIT_HEADLINE"
                  as="h4"
                  variant="order4"
                  spaceStackEnd="s"
                  spaceStackStart="m"
                >
                  {benefitHeadlineLabel}
                </Text>
                <Text as="p" variant="copy1" spaceStackEnd="s" spaceStackStart="s">
                  {benefitSubHeadlineLabel}
                </Text>
                <RichText nodeRenderer={coreRenderer}>{benefitContent}</RichText>

                {benefitMarketUrl && (
                  <Button
                    aria-label="My Audi Page"
                    variant="text"
                    spaceStackEnd="xl"
                    data-testid="FAUSERMENU_DETAILSLINK"
                    onClick={handleDetailsClick}
                  >
                    <Text variant="copy1">{benefitMarketUrl}</Text>
                  </Button>
                )}
                <Button
                  aria-label="My Audi Login"
                  data-testid="FAUSERMENU_LOGINBUTTON"
                  variant="primary"
                  spaceStackEnd="s"
                  stretch
                  onClick={handleLoginClick}
                >
                  {benefitLoginLabel}
                </Button>
                <Button
                  onClick={handleRegistrationClick}
                  aria-label="Registration Page"
                  variant="secondary"
                  stretch
                >
                  {benefitMoreLabel}
                </Button>
                {Array.isArray(supplier) &&
                  supplier.map((supplierItem) => (
                    <Layout
                      key={`${supplierItem.Name}-${supplierItem.LinkLabel}`}
                      spaceStackStart="l"
                    >
                      <Text as="span" variant="copy2" spaceInlineEnd="m">
                        {supplierItem?.Name}
                      </Text>
                      <Text as="p" variant="copy2">
                        <a href={supplierItem?.LinkUrl} target="_blank" rel="noreferrer">
                          {supplierItem?.LinkLabel}
                        </a>
                      </Text>
                    </Layout>
                  ))}
              </PopoverBody>
            </StyledPopoverContainer>
          </>
        )}
      </PopoverTrigger>
    );
  };

  const renderLoggedInState = () => {
    const handleLogoutClick = (event: React.MouseEvent) => {
      event.preventDefault();
      const LogoutClickEvent: TrackingDataV2 = {
        event: {
          eventInfo: {
            eventAction: EventAction.INTERNAL,
            eventName: 'one header - myaudi - click on logout',
          },
          attributes: {
            componentName: EventAction.COMPONENT_NAME,
            label: benefitLogout,
            targetURL: '',
            clickID: '',
            elementName: 'button',
            value: '',
            pos: '',
          },
        },
      };
      trackingService?.track(LogoutClickEvent);
      authService.logout(redirectUrl);
    };

    const handleMyAccountClick = (event: React.MouseEvent) => {
      event.preventDefault();
      const myAccountClickEvent: TrackingDataV2 = {
        event: {
          eventInfo: {
            eventAction: EventAction.INTERNAL,
            eventName: 'one header - myaudi - click on myaudi account',
          },
          attributes: {
            componentName: EventAction.COMPONENT_NAME,
            label: 'myAudi Account',
            targetURL: '',
            clickID: '',
            elementName: 'text link',
            value: '',
            pos: `${headerNavigationItemsAmount + totalActionItems} - 1`,
          },
        },
      };
      trackingService?.track(myAccountClickEvent);
      window.location.href = userAccountUrl;
    };

    // eslint-disable-next-line
    const avatarComponent = personalData ? (
      profilePicture.profilePicture?.src ? (
        <StyledAvatar
          size="small"
          label={!profilePicture.profilePicture?.src ? initials : ''}
          src={profilePicture.profilePicture.src}
        />
      ) : (
        <StyledAvatar size="small" label={initials} />
      )
    ) : null;

    return (
      <PopoverTrigger placement="bottom right" tip>
        {(triggerProps) => (
          <>
            <Button
              aria-label={flyoutLabel}
              variant="icon-tertiary"
              data-testid="FAUSERMENU_USERICON"
              icon={avatarComponent}
              size="small"
              className="tooltip"
              {...triggerProps}
            />
            <StyledPopoverContainer aria-label="myAudi Customer Area">
              <PopoverBody>
                <StyledLayout direction="column">
                  <Button
                    aria-label="myAudi Account"
                    data-testid="FAUSERMENU_LINKPORTALACCOUNT"
                    onClick={handleMyAccountClick}
                    variant="text"
                    icon="user"
                    type="button"
                  >
                    {benefitMyAudi}
                  </Button>
                  <Button
                    data-testid="FAUSERMENU_LINKLOGOUT"
                    spaceStackStart="l"
                    aria-label="Logout"
                    onClick={handleLogoutClick}
                    variant="text"
                    icon="logout"
                  >
                    {benefitLogout}
                  </Button>
                </StyledLayout>
              </PopoverBody>
            </StyledPopoverContainer>
          </>
        )}
      </PopoverTrigger>
    );
  };

  if (useUserMenu === undefined) {
    return null;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AppLoaderErrorBoundary>
      <HeaderStyledLogin
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        isOpen={isOpen}
        onClick={onClickUserMenu}
        data-testid="FAUSERMENU_TOGGLELINK"
      >
        {
          // eslint-disable-next-line
          useUserMenu ? (
            isAuthenticated ? (
              renderLoggedInState()
            ) : (
              renderLoggedOutState()
            )
          ) : (
            <FeatureAppLoader featureAppId="header-login" src={loginFeatureAppUrl} />
          )
        }
      </HeaderStyledLogin>
    </AppLoaderErrorBoundary>
  );
};

export default AudiUserMenu;
