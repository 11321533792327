/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TrackingDataV2 } from '@oneaudi/audi-tracking-service';

export const appReady: TrackingDataV2 = {
  event: {
    eventInfo: {
      eventAction: 'feature_app_ready',
      eventName: 'one header - feature app ready',
    },
    attributes: {
      componentName: 'one-header',
      label: '',
      targetURL: '',
      clickID: '',
      elementName: '',
      value: '',
      pos: '',
    },
  },
};

export enum EventAction {
  INTERNAL = 'internal_link',
  EXIT = 'exit_link',
  COMPONENT_NAME = 'one-header',
  MAIN_NAVIGATION = 'main-navigation',
  NAVIGATION = 'navigation',
  NONE_INTERACTION = 'non_interaction',
}

const getIndexPosition = (index: number): number => {
  const indexPosition = index + 1;
  return indexPosition;
};

const isAbsoluteURL = (url: string): boolean => {
  const r = /^(?:[a-z+]+:)?\/\//i;
  return r.test(url);
};

export function getLinkType(originUrl: string, targetUrl: string): EventAction {
  if (isAbsoluteURL(targetUrl) && isAbsoluteURL(originUrl)) {
    if (new URL(originUrl).host !== new URL(targetUrl).host) {
      return EventAction.EXIT;
    }
  }

  return EventAction.INTERNAL;
}

export function createBaseTrackingData(eventName: string, componentName: string): TrackingDataV2 {
  return {
    event: {
      eventInfo: {
        eventAction: 'navigation',
        eventName,
      },
      attributes: {
        componentName,
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        pos: '',
        value: '',
      },
    },
  };
}

export function myAudiPopoverClick(title: string, positionIndex: number): TrackingDataV2 {
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    `one header - ${title}`,
    EventAction.COMPONENT_NAME,
  );
  if (trackingStructure.event.attributes) {
    trackingStructure.event.attributes.elementName = 'text link';
    trackingStructure.event.attributes.label = 'myAudi';
    trackingStructure.event.attributes.clickID = '';
    trackingStructure.event.attributes.pos = positionIndex.toString();
  }

  return trackingStructure;
}

export function mobileMenuClick(
  title: string,
  positionIndex: number,
  elementName: string,
): TrackingDataV2 {
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    `one header - ${title}`,
    EventAction.COMPONENT_NAME,
  );
  if (trackingStructure.event.attributes) {
    trackingStructure.event.attributes.elementName = elementName;
    trackingStructure.event.attributes.label = '';
    trackingStructure.event.attributes.clickID = '';
    trackingStructure.event.attributes.pos = getIndexPosition(positionIndex).toString();
  }

  return trackingStructure;
}

export function logoTrackingData(dynamicTargetURL?: string): TrackingDataV2 {
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    `one header - click on audi logo`,
    EventAction.COMPONENT_NAME,
  );
  const eventAction = getLinkType(window.location.href, dynamicTargetURL || '');
  if (trackingStructure.event.attributes) {
    trackingStructure.event.attributes.targetURL = dynamicTargetURL;
    trackingStructure.event.attributes.elementName = 'icon';
    trackingStructure.event.attributes.label = 'Audi Logo';
    trackingStructure.event.attributes.clickID = 'nm-main-navigation-item-info';
  }
  trackingStructure.event.eventInfo.eventAction = eventAction;

  return trackingStructure;
}

export function logoutClickOnWishlist(): TrackingDataV2 {
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    'one header - click on heart (user not logged in)',
    EventAction.COMPONENT_NAME,
  );
  if (trackingStructure.event.attributes) {
    trackingStructure.event.attributes.elementName = 'icon';
    trackingStructure.event.attributes.label = 'Heart';
  }
  return trackingStructure;
}

export function loginClickOnWishlist(dynamicTargetURL: string): TrackingDataV2 {
  const eventAction = getLinkType(window.location.href, dynamicTargetURL);
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    'one header - click on heart',
    EventAction.COMPONENT_NAME,
  );

  if (trackingStructure.event.attributes) {
    trackingStructure.event.attributes.targetURL = dynamicTargetURL;
    trackingStructure.event.attributes.elementName = 'icon';
    trackingStructure.event.attributes.label = 'Heart';
  }
  trackingStructure.event.eventInfo.eventAction = eventAction;

  return trackingStructure;
}

export function mainNavigationToggle(
  title: string,
  element: Element | null,
  positionIndex: number,
): TrackingDataV2 {
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    `one header - ${title}`,
    EventAction.COMPONENT_NAME,
  );

  if (trackingStructure.event.attributes && element) {
    const linkLabel = element.getElementsByTagName('span');
    trackingStructure.event.attributes.componentName = EventAction.COMPONENT_NAME;
    trackingStructure.event.attributes.elementName = 'text link';
    trackingStructure.event.attributes.label = `Menu > ${linkLabel?.[0]?.innerText}`;
    trackingStructure.event.attributes.clickID = element.getAttribute('data-testid');
    trackingStructure.event.attributes.targetURL = element.getAttribute('href');
    trackingStructure.event.attributes.pos = getIndexPosition(positionIndex).toString();
  }
  trackingStructure.event.eventInfo.eventAction = 'navigation';

  return trackingStructure;
}

export function subNavigationLinkClick(
  title: string,
  element: Element | null,
  positionIndex: number,
): TrackingDataV2 {
  const trackingStructure: TrackingDataV2 = createBaseTrackingData(
    `one header - ${title}`,
    EventAction.MAIN_NAVIGATION,
  );
  const url = element?.getAttribute('href');
  const eventAction = getLinkType(window.location.href, url || '');
  if (trackingStructure.event.attributes && element) {
    const parentElement = element?.closest('div[role="tabpanel"]');
    const parentLink = parentElement?.previousElementSibling;
    const parentLabel = parentLink?.getElementsByTagName('span');
    // @ts-ignore
    const elementIndex = getIndexPosition(Number(element?.dataset.index));

    // @ts-ignore
    const linkLabel = element?.innerText;
    trackingStructure.event.attributes.componentName = EventAction.MAIN_NAVIGATION;
    trackingStructure.event.attributes.elementName = 'text link';
    trackingStructure.event.attributes.label = `Menu > ${parentLabel?.[0]?.innerText} > ${linkLabel}`;
    trackingStructure.event.attributes.clickID = parentLink?.getAttribute('data-testid');
    trackingStructure.event.attributes.targetURL = url;
    trackingStructure.event.attributes.pos = `${getIndexPosition(positionIndex)}-${elementIndex}`;
  }
  trackingStructure.event.eventInfo.eventAction = eventAction;

  return trackingStructure;
}
